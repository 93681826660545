<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      autocomplete="off"
    >
      <b-row>
        <b-col cols="12 pt-3">
          <validation-provider
            name="Başlık"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button
              squared
              block
              type="submit"
              size="lg"
              variant="primary"
              class="mr-2"
            >
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{
                form.k_no == null ? "EKLE" : "GÜNCELLE"
              }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import {
  defineComponent,
  ref,
  computed,
  toRefs,
  watch,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { useRouter } from "@/libs/utils";

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};

    const { route } = useRouter();
    const toast = useToast();
    const { updateData } = toRefs(props);

    expo.form = ref({
      _id: null,
      k_no: null,
      baslik: null,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        baslik: null,
      };
    };

    const handlarFethData = async () => {
      const k_no = route.value.params.k_no;
      if (!Object.values(updateData.value).length) {
        context.emit("show", true);
        await store.dispatch("medyaOrtamFindOne", k_no).then((res) => {
          if (res.data.success) {
            expo.form.value = res.data.data;
            context.emit("show", false);
          }
        });
      }
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("medyaOrtamGuncelle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Güncelleme Başarılı", { position: "bottom-left" });
            context.emit("show", false);
            context.emit("updateRefresh", res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          const k_no = route.value.params.k_no;
          if (!k_no) {
            expo.resetForm();
          } else {
            handlarFethData();
          }
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
